import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";

// geolocation and native-geocoder
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthenticationService } from "./providers/authentication.service";
import { RouteReuseStrategy } from "@angular/router";
import { SpaService } from "./providers/spa.service";
import { PopoverComponent } from '../app/components/popover/popover.component'
import { TermsModalComponent } from '../app/components/terms-modal/terms-modal.component'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { OrderModule } from 'ngx-order-pipe';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { ServiceDetailsPage } from "./pages/service-details/service-details.page";
import { HomePageFeedsPage } from "./pages/home-page-feeds/home-page-feeds.page";

import { QuillModule } from "ngx-quill";
import { AuthInterceptor } from "./guards/auth.interceptor";
import { FooterComponent } from "./components/footer/footer.component";
import { AddEditModalComponent } from "./components/add-edit-modal/add-edit-modal.component";
// import { NgxPayPalModule } from "ngx-paypal";


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    //NgxPayPalModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    IonicModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3NLU1OTZpjI3UxHEiAkcBbzFQjC9PCJM'
    }),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    OrderModule,
    NgbRatingModule
  ],
  exports: [
    // QuillModule
  ],
  declarations: [AppComponent, PopoverComponent, TermsModalComponent,FooterComponent, AddEditModalComponent],
  entryComponents: [PopoverComponent, TermsModalComponent, AddEditModalComponent],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    AuthenticationService,
    SpaService,
    Camera,
    SocialSharing,
    Geolocation,
    ServiceDetailsPage,
    HomePageFeedsPage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
