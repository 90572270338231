import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
const USER_DATA = 'user-data';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpaService {

  
  constructor(private http: HttpClient, private storage: Storage) { 
  }



  getAllSpasList(): Observable<any> {
    return this.http.get(`https://spazada.azurewebsites.net/api/appSpa?site_id=1&country_id=154&skip=0&page_size=15&service_id=33&city=&rating=&keyword=`)
  }

  getSpaDetails(spaId): Observable<any> {
    return this.http.get(`https://spazada.azurewebsites.net/api/appSpa?spa_id=${spaId}`)
  }


}
