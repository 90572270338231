import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from '../../providers/app.services';
import { MessageService } from '../../providers/message.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {

  @Input() showtype;
  @Input() spaData;
  showType: any = '';
  spaProfileDetails: any = '';
  currentRate: any = 4;
  spadata: any = '';
  spaReview = {
    instructorRate: 0,
    materialRate: 0,
    environmentRate: 0,
    servicesRate: 0,
    overAllRate: 0,
    text: ''
  }

  constructor(
    private modalCtrl: ModalController,
    private appService: AppService,
    private toast: MessageService
  ) { }

  ngOnInit() {
    this.showType = this.showtype;
  }

  dismissModal() {
    this.modalCtrl.dismiss(this.spaReview);
  }

  submitReview() {
    var comments_obj = {
      review_Course: this.spaData.spa_title,
      student_email: '',
      evaluation_instructor_score: this.spaReview.instructorRate,
      evaluation_material_score: this.spaReview.materialRate,
      evaluation_environment_score: this.spaReview.environmentRate,
      evaluation_service_score: this.spaReview.servicesRate,
      evaluation_overall_score: this.spaReview.overAllRate,
      evaluation_comments: this.spaReview.text,
      fk_school_id: this.spaData.pk_spa_id,
      evaluator_gender: null,
      evaluator_age: null
    }

    this.appService.setSchoolReviewComments(comments_obj).subscribe(
      async (res) => {
        this.toast.showToaster(res.Item);
      },
      async (error) => {
        this.toast.showToaster("Something went wrong when submitting ratings.")
      }
    );

  }

}
