import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
const USER_DATA = 'user-data';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  userdata = '';
  
  constructor(private http: HttpClient, private storage: Storage) { 
    this.loadToken();
  }

  async loadToken() {
    const userData = await this.storage.get(USER_DATA);
    console.log(userData)  
    if (userData) {
      console.log('set userdata: ', userData);
      this.userdata = JSON.parse(userData);
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: {email, password, usertype}): Observable<any> {
    return this.http.post(`https://spazada.azurewebsites.net/api/appLogin/`, credentials).pipe(
      map((data: any) => data.Item),
      switchMap(user => {
          return from(this.storage.set(USER_DATA, JSON.stringify(user)));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }

  setFeedback(contactOption): Observable<any> {
    return this.http.post(`https://spazada.azurewebsites.net/api/Feedback/`, contactOption)
  }

  register(credentials: {email, password}): Observable<any> {
    return this.http.post(`https://spazada.azurewebsites.net/api/user/`, credentials)
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return this.storage.remove(USER_DATA);
  }
}
