import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppService } from '../../providers/app.services';
import { MessageService } from '../../providers/message.service';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
})
export class AddEditModalComponent implements OnInit {

  @Input() pkgData;
  @Input() spaData;
  package = { add: '' }
  showType: any = '';
  spaOwner_id: any;
  webPage: boolean = false;
  countryId: any = '';
  eyeStatus: boolean = false;
  newPackageParam: any = {
    fk_school_id: '',
    course_title: '',
    course_isactive: true,
    fk_language_id: 'null',
    course_fee: '',
    duration: '',
    course_original_fee: '',
    course_description: '',
    pk_course_id: '',
  };
  newSpaParam: any = {
    school_title: '',
    school_email: '',
    school_city: '',
    school_website: '',
    school_description: '',
    fk_country_id: '',
    fk_language_id: 'null',
    school_password: ''
  }
  constructor(
    private modalCtrl: ModalController,
    private message: MessageService,
    private appService: AppService,
    private storage: Storage
  ) {
    this.message.feedWeb.subscribe(res => {
      this.webPage = res;
    })
  }


  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async ngOnInit() {
    this.countryId = await this.storage.get("countryId");
    this.newSpaParam.fk_country_id = this.countryId;
    if (this.pkgData.add == false) {
      this.packageDetails(this.pkgData.pkgId)
    }
    else {
      // Do nothing
    }
  }

  async packageDetails(pkgId) {

    let packageParam = {
      package_id: pkgId,
      country_id: this.countryId
    }
    this.appService.getPackageDetails(packageParam).subscribe(
      async (res) => {
        let val = res.Item;
        this.newPackageParam = {
          fk_school_id: 11154,
          course_title: val.package_title,
          course_isactive: true,
          fk_language_id: 'null',
          course_fee: Number(val.package_fee),
          duration: Number(val.package_duration),
          course_original_fee: Number(val.package_original_fee),
          course_description: val.package_description,
          pk_course_id: pkgId
        }
      });
  };

  // Updating a package with its records.
  async updatePackage() {
    if (this.newPackageParam.fk_language_id == 'null') {
      this.message.showToaster("Please select a category");
    }
    else {
      this.appService.updateAPackage(this.newPackageParam).subscribe(
        async (res) => {
          this.message.showToaster("Package Updated");
          this.packageDetails(this.pkgData.pkgId);
          this.dismissModal();
        },
        async (error) => {
          this.message.showToaster('Something went wrong.');
        });
    }
  };

  // Adding new package
  async addPackage() {
    if (this.newPackageParam.fk_language_id == 'null' || !this.newPackageParam.course_title || !this.newPackageParam.course_original_fee || !this.newPackageParam.course_fee || !this.newPackageParam.duration || !this.newPackageParam.course_description) {
      this.message.showToaster("Please fill all information above");
    }
    else {
      this.appService.addAPackage(this.newPackageParam).subscribe(
        async (res) => {
          this.message.showToaster("Package Added");
          this.packageDetails(this.pkgData.pkgId)
          this.dismissModal();
        },
        async (error) => {
          this.message.showToaster('Something went wrong.');
        });
    }
  };

  // Adding new package
  async addSpa() {
    if (!this.newSpaParam.school_title || !this.newSpaParam.school_email || !this.newSpaParam.school_city || !this.newSpaParam.school_website || !this.newSpaParam.school_description || this.newSpaParam.fk_language_id == 'null') {
      this.message.showToaster("Please fill all information above");
    }
    else {
      this.appService.addNewSpa(this.newSpaParam).subscribe(
        async (res) => {
          this.message.showToaster("Spa Added");
          this.dismissModal();
        },
        async (error) => {
          this.message.showToaster('Something went wrong.');
        });
    }
  };

  clickEvent() {
    this.eyeStatus = !this.eyeStatus;
  }

}
