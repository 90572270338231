import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IonTabs, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppService } from '../../providers/app.services';
import { MessageService } from '../../providers/message.service';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationExtras } from '@angular/router';
import { Geolocation } from '@ionic-native/geolocation/ngx';


@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.page.html',
  styleUrls: ['./service-details.page.scss'],
})
export class ServiceDetailsPage implements OnInit {

  curlat: any;
  curlong: any;
  search = false;
  segment = 'place';
  spaList = [];
  packageList = [];
  videoList = [];
  peopleList = [];
  articleList = [];
  productList = [];
  skip: any = 0;
  page_size: any = 15;
  spaDetail = {
    keyword: ''
  }
  showSearch: any = false;
  subCatList = [];
  cities = [];
  packages = [];
  Subcatid: any
  serviceId: any = '';
  countryId: any = 154;
  subCatId: any = '';
  searchPackages: any = '';
  selectedCity: any;
  selectedPackage: any;
  selectedRating: any;
  rangeValues = {
    min: 200,
    max: 600
  }
  goToMainCategory: boolean = false;
  moreData: any;
  order = '+spa_title';
  reverse: boolean = false;
  tempSpa: any[] = [];
  tempPackages: any[] = [];
  tempVideo: any[] = [];
  tempProducts: any[] = [];
  tempUsers: any[] = [];
  tempForums: any[] = [];
  purchaseCount: any[] = [1, 2, 3, 4, 5]
  spaLoader: boolean = true;
  packageLoader: boolean = true;
  videoLoader: boolean = true;
  productLoader: boolean = true;
  userLoader: boolean = true;
  articleLoader: boolean = true;
  webPage: boolean = false;
  sortBy: any; // Not used anywhere
  rating3: any; // Not used anywhere

  constructor(
    private loadingController: LoadingController,
    private router: Router,
    private route: ActivatedRoute,
    private storage: Storage,
    private appService: AppService,
    private toaster: MessageService,
    private title: Title,
    private message: MessageService,
    private toast: MessageService,
    private geoLocation: Geolocation,
    private meta: Meta
  ) {
    this.message.feedWeb.subscribe(res => {
      this.webPage = res;
    })
  }

  ratingList = [
    {
      "rating": 1, "rating_name": "All"
    },
    {
      "rating": 2, "rating_name": "1 or more stars"
    },
    {
      "rating": 3, "rating_name": "2 or more stars"
    },
    {
      "rating": 4, "rating_name": "3 or more stars"
    },
    {
      "rating": 5, "rating_name": "4 or more stars"
    },
    {
      "rating": 6, "rating_name": "5 stars"
    }];

  async ngOnInit() {
    //debugger;
    this.title.setTitle('Categories');
    this.meta.addTags([
      { name: 'keywords', content: 'Spa Studio, spa.studio, spa' },
      { name: 'description', content: 'Spa Studio where you can find piece and relaxation for you soul' },
      { name: 'robots', content: 'index, follow' }
    ]);

    this.route.queryParams.subscribe(params => {
      let id=params.id;
      //debugger;
    })
  }

  async ionViewDidEnter() {
    this.countryId = await this.storage.get("countryId");
    this.geoLocation.getCurrentPosition().then(pos => {
      console.log(pos);
      this.curlat = pos.coords.latitude;
      this.curlong = pos.coords.longitude;
      this.getPackages();
    })
    this.serviceId = await this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      //debugger;
      if (params && params.id) {
        this.serviceId = params.id;
      }
      if (params.recordtype == 'referenceVideo') {
        this.segment = 'video';
        this.getServiceVideos();
      }
      if (params.recordtype == 'specialOfferDetail') {
        this.segment = 'package';
        this.getPackages();
      }
      if (params.recordtype == 'productDetails') {
        this.segment = 'product';
        this.getServiceProducts();
      }
      if (params.recordtype == 'spaProfile') {
        this.segment = 'place';
        this.getAvailableSpa();
      }
      if (params.recordtype == 'parleyArticle') {
        this.segment = 'forum';
        this.getParleyArticles();
      }
      if (params.recordtype == 'user') {
        this.segment = 'user';
        this.getUsers();
      }
    })

    this.getAvailableSpa();
    // this.getPackages();
    this.getServiceVideos();
    this.getServiceProducts();
    this.getParleyArticles();
    this.getUsers();
    this.getSearchDropdownData();
    this.getSubCategory();

  }

  async ngAfterViewInit() {
  }

  // calculates Distance in KM for spa list
  computeDistanceBetween(lat2, lon2) {
    // let lat1: any = localStorage.getItem('latcurr');
    // let lon1: any = localStorage.getItem('longcurr');

    let lat1: any = this.curlat;
    let lon1: any = this.curlong;
    //console.log("Current Location: "+ lat1 + "," + lat2)

    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  changeLocation() {
    //Empty Function
  }

  segmentChanged(type) {
    this.segment = type;
    if (this.segment == 'place')
      this.getAvailableSpa();
    if (this.segment == 'package')
      this.getPackages();
    if (this.segment == 'video')
      this.getServiceVideos();
    if (this.segment == 'product')
      this.getServiceProducts();
    if (this.segment == 'user')
      this.getUsers();
    if (this.segment == 'forum')
      this.getParleyArticles();
  }

  spaparam: any = {
    countryId: this.countryId,
    flag: false,
    skip: this.skip,
    page_size: this.page_size,
    serviceId: this.serviceId,
    city: '',
    rating: '',
    keyword: this.spaDetail.keyword,
  }

  async getAvailableSpa() {

    this.spaparam.countryId = this.countryId
    this.spaparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId
    this.spaparam.keyword = this.spaDetail.keyword

    this.appService.getAllSpasList(this.spaparam).subscribe(
      async (res) => {
        this.tempSpa = res.Items
        if (res.Items) {
          this.spaLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.spaList = [];
          this.spaList = this.spaList.concat(res.Items);
        }
        else if (this.moreData) {
          this.spaList = this.spaList.concat(res.Items);
          this.moreData = false;
        }
        else {
          this.spaList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading places.");
      }
    );
  }

  packagesparam = {
    countryId: this.countryId,
    flag: false,
    skip: this.skip,
    page_size: this.page_size,
    serviceId: this.serviceId,
    city: '',
    rating: '',
    keyword: '',
    cur_lat: '',
    cur_long: ''
  }
  async getPackages() {

    // this.lastView = $ionicHistory.backView();
    this.packagesparam.cur_lat = this.curlat;
    //console.log("Lat" + this.packagesparam.cur_lat);
    //this.packagesparam.cur_long = this.curlong;
    this.packagesparam.countryId = this.countryId
    this.packagesparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId
    this.packagesparam.keyword = this.spaDetail.keyword

    this.appService.getAllPackageList(this.packagesparam).subscribe(
      async (res) => {
        this.tempPackages = res.Items;
        if (res.Items) {
          this.packageLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.packageList = [];
          this.packageList = this.packageList.concat(res.Items);
        }
        else if (this.moreData) {
          this.packageList = this.packageList.concat(res.Items);
        }
        else {
          this.packageList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading package.");
      }
    );
  }

  videoparam = {
    skip: this.skip,
    page_size: this.page_size,
    serviceId: this.serviceId,
    keyword: this.spaDetail.keyword
  }
  async getServiceVideos() {
    // this.lastView = $ionicHistory.backView();

    this.videoparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId
    this.videoparam.keyword = this.spaDetail.keyword

    this.appService.getAllVideoList(this.videoparam).subscribe(
      async (res) => {
        this.tempVideo = res.Items;
        if (res.Items) {
          this.videoLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.videoList = [];
          this.videoList = this.videoList.concat(res.Items);
        }
        else if (this.moreData) {
          this.videoList = this.videoList.concat(res.Items);
        }
        else {
          this.videoList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading video.");
      }
    );
  }

  productparam: any = {
    skip: this.skip,
    page_size: this.page_size,
    serviceId: 33,
    keyword: this.spaDetail.keyword,
  }
  async getServiceProducts() {

    const countryId = await this.storage.get("countryId");
    this.productparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId
    this.productparam.keyword = this.spaDetail.keyword

    this.appService.getAllProductList(this.productparam).subscribe(
      async (res) => {
        this.tempProducts = res.Items;
        if (res.Items) {
          this.productLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.productList = [];
          this.productList = this.productList.concat(res.Items);
        }
        else if (this.moreData) {
          this.productList = this.productList.concat(res.Items);
        }
        else {
          this.productList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading products.");
      }
    );
  }

  peopleparam: any = {
    skip: this.skip,
    page_size: this.page_size,
    serviceId: 33,
    keyword: this.spaDetail.keyword,
  }
  async getUsers() {

    this.peopleparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId
    this.peopleparam.keyword = this.spaDetail.keyword

    this.appService.getAllPeopleList(this.peopleparam).subscribe(
      async (res) => {
        this.tempUsers = res.Items;
        if (res.Items) {
          this.userLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.peopleList = [];
          this.peopleList = this.peopleList.concat(res.Items);
        }
        else if (this.moreData) {
          this.peopleList = this.peopleList.concat(res.Items);
        }
        else {
          this.peopleList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading users.");
      }
    );
  }

  articleparam: any = {
    skip: this.skip,
    page_size: this.page_size,
    serviceId: 33,
    keyword: this.spaDetail.keyword,
  }
  async getParleyArticles() {

    this.articleparam.keyword = this.spaDetail.keyword
    this.articleparam.serviceId = this.Subcatid ? this.Subcatid : this.serviceId

    this.appService.getAllArticleList(this.articleparam).subscribe(
      async (res) => {
        this.tempForums = res.Items;
        if (res.Items) {
          this.articleLoader = false
        }
        if (this.Subcatid || this.spaDetail.keyword) {
          this.articleList = [];
          this.articleList = this.articleList.concat(res.Items);
        }
        else if (this.moreData) {
          this.articleList = this.articleList.concat(res.Items);
        }
        else {
          this.articleList = res.Items;
        }
      },
      async (error) => {
        this.toast.showToaster("Something went wrong while loading forums.");
      }
    );
  }

  loadMoreSpaData(event) {
    this.moreData = true;
    //this.spaparam.skip += this.spaparam.page_size;
    if(this.search == true){
      this.spaparam.skip = 0;
    }
    else{
      this.spaparam.skip += this.spaparam.page_size;
    }
    this.getAvailableSpa();
    setTimeout(() => {
      event.target.complete();
    }, 500);

  }

  loadMorePackageData(event) {
    this.moreData = true;
    if(this.search == true){
      this.packagesparam.skip = 0;
    }
    else{
      this.packagesparam.skip += this.packagesparam.page_size;
    }
    this.getPackages();
    setTimeout(() => {
      event.target.complete();
    }, 500);

  }

  loadMoreVideoData(event) {
    this.moreData = true;
    if(this.search == true){
      this.videoparam.skip = 0;
    }
    else{
      this.videoparam.skip += this.videoparam.page_size;
    }
    this.getServiceVideos();
    setTimeout(() => {
      event.target.complete();
    }, 500);

  }

  loadMoreProductData(event) {
    this.moreData = true;
    if(this.search == true){
      this.productparam.skip = 0;
    }
    else{
      this.productparam.skip += this.productparam.page_size;
    }
    this.getServiceProducts();
    setTimeout(() => {
      event.target.complete();
    }, 500);

  }

  loadMoreUserData(event) {
    this.moreData = true;
    if(this.search == true){
      this.peopleparam.skip = 0;
    }
    else{
      this.peopleparam.skip += this.peopleparam.page_size;
    }
    this.getUsers();
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  loadMoreForumData(event) {
    this.moreData = true;
    if(this.search == true){
      this.articleparam.skip = 0;
    }
    else{
      this.articleparam.skip += this.articleparam.page_size;
    }
    this.getParleyArticles();
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  changeSubCategory(event) {
    this.Subcatid = event.detail.value;
    this.goToMainCategory = true;

    this.getAvailableSpa();
    setTimeout(() => { this.getPackages(); }, 100);
    setTimeout(() => { this.getServiceVideos(); }, 200);
    setTimeout(() => { this.getServiceProducts(); }, 300);
    setTimeout(() => { this.getUsers(); }, 400);
    setTimeout(() => { this.getParleyArticles(); }, 500);
  }

  goBackToMainCat() {
    this.goToMainCategory = false;
    this.Subcatid = this.serviceId;
    this.getAvailableSpa();
    setTimeout(() => { this.getPackages(); }, 100);
    setTimeout(() => { this.getServiceVideos(); }, 200);
    setTimeout(() => { this.getServiceProducts(); }, 300);
    setTimeout(() => { this.getUsers(); }, 400);
    setTimeout(() => { this.getParleyArticles(); }, 500);

  }

  changeSortOption(event) {
    this.order = event.detail.value;
    this.reverse = !this.reverse;
  }

  //getting the sub-Category list
  getSubCategory() {

    let subCatParam = {
      fk_parent_id: this.serviceId,
      type: 'subCategory'
    }

    this.appService.getsubCateogry(subCatParam).subscribe(
      async (res) => {
        this.subCatList = res.Items;
      },
      async (error) => {
        this.toaster.showToaster('Something went wrong');

      }
    );
  }

  async getSearchDropdownData() {

    let paramCity = {
      type: '_search_city',
      country_id: this.countryId
    }

    this.appService.getSearchDropdownData(paramCity).subscribe(
      async (res) => {
        this.cities = res.Items;
        this.cities.splice(0, 0, {
          "value": null,
          "svalue": null,
          "text": "All",
          "icon": null
        });
        this.selectedCity = this.cities[0];
      });


    let paramPackage = {
      type: '_search_package',
      country_id: this.countryId
    }
    this.appService.getSearchDropdownData(paramPackage).subscribe(
      async (res) => {
        this.searchPackages = res.Items;
        this.searchPackages.splice(0, 0, {
          "value": null,
          "svalue": null,
          "text": "All",
          "icon": null
        });
        this.selectedPackage = this.searchPackages[0];
      });
  }

  changeSearchService() {
    // code remains
  }

  changSearchRating() {
    // code remains
  }

  searchOptions() {
    this.showSearch = !this.showSearch;
  }

  startSearch() {
    this.search = true;
    var spaDetail = {
      country_id: this.countryId,
      city: this.selectedCity.text,
      language_id: this.selectedPackage.text,
      rating: this.selectedRating,
      keyword: this.spaDetail.keyword,
      recordtype: "title",
      min_price: this.rangeValues.min,
      max_price: this.rangeValues.max
    }

    if (this.spaDetail.keyword) {
      if (this.segment == 'place')
        this.getAvailableSpa();
      if (this.segment == 'package')
        this.getPackages();
      if (this.segment == 'video')
        this.getServiceVideos();
      if (this.segment == 'product')
        this.getServiceProducts();
      if (this.segment == 'user')
        this.getUsers();
      if (this.segment == 'forum')
        this.getParleyArticles();
    }
    else {
      this.toast.showToaster("Please enter a keyword to search");
    }
  }

  resetSearch() {
    this.search = false;
    this.showSearch = !this.showSearch;
    this.spaDetail.keyword = '';

    if (this.segment == 'place') {
      this.spaList = [];
      this.getAvailableSpa();
    }
    if (this.segment == 'package') {
      this.packageList = [];
      this.getPackages();

    }
    if (this.segment == 'video') {
      this.videoList = [];
      this.getServiceVideos();

    }
    if (this.segment == 'product') {
      this.productList = [];
      this.getServiceProducts();

    }
    if (this.segment == 'people') {
      this.peopleList = [];
      this.getUsers();

    }
    if (this.segment == 'forum') {
      this.articleList = [];
      this.getParleyArticles();

    }
  }

  goToPlaceDetails(data) {
    //this.router.navigateByUrl('/dashboard/spa-details/' + data.pk_spa_id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: data.pk_spa_id,
        from: 'service'
      }
    }
    this.router.navigate(['/dashboard/spa-details'], navigationExtras);
  }

  goToPackageDetails(data) {
    //this.router.navigateByUrl('/dashboard/package-details/' + data.package_id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: data.package_id,
        serviceid: this.serviceId,
        from: 'service'
      }
    }
    this.router.navigate(['/dashboard/package-details'], navigationExtras);
  }

  goToVideoDetails(id) {
    //this.router.navigateByUrl('/dashboard/video-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        serviceid: this.serviceId,
        from: 'service'
      }
    }
    this.router.navigate(['/dashboard/video-details'], navigationExtras);
  }

  goToProductDetails(id) {
    //this.router.navigateByUrl('/dashboard/product-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        serviceid: this.serviceId,
        from: 'service'
      }
    }
    this.router.navigate(['/dashboard/product-details'], navigationExtras);
  }

  goToPeopleDetails(id) {
    //this.router.navigateByUrl('/dashboard/person-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        serviceid: this.serviceId
      }
    }
    this.router.navigate(['/dashboard/person-details'], navigationExtras);
  }

  goToForumDetails(id) {
    // this.router.navigateByUrl('/dashboard/article-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        serviceid: this.serviceId,
        from: 'service'
      }
    }
    this.router.navigate(['/dashboard/article-details'], navigationExtras);
  }

  addNewArticle() {
    this.router.navigateByUrl('/dashboard/add-article/' + 2);
  }

}
