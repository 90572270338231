import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
const USER_DATA = 'user-data';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from, Subject } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public bookedDate = new BehaviorSubject<any>(null);
  public bookedDay = new BehaviorSubject<any>(null);
  public fullDate = new BehaviorSubject<any>(null);
  public selectedStaff = new BehaviorSubject<any>(null);
  public package_duration = new BehaviorSubject<any>(null);
  public Subcatid = new BehaviorSubject<any>(null);
  public feedWeb =  new BehaviorSubject<any>(false);
  public spaOwner =  new BehaviorSubject<any>(false);

  constructor(private http: HttpClient, private storage: Storage, public toast: ToastController) {
  }

  async showToaster(message) {
    const toast = await this.toast.create({
      color: 'dark',
      duration: 1500,
      message: message
    });
    toast.present();
  }

}
