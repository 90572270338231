import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Input() feedData;
  id: any;
  title: any;
  cate_title: any;
  category_id: any;
  recordType: any;
  show = false;

  list: any = [{
    icon: "fa-heart-o",
    name: "Like Video"
  },
  {
    icon: "fa-external-link",
    name: "Open"
  },
  {
    icon: "fa-folder-open-o",
    name: "Similar Items"
  }
  ]

  constructor(private popoverCtrl: PopoverController, private router: Router) {
  }

  ngOnInit() {
    this.id = this.feedData.id;
    this.title = this.feedData.title;
    this.cate_title = this.feedData.cate_title;
    this.category_id = this.feedData.category_id;
    this.recordType = this.feedData.recordType;

  }

  dismissPopover(type) {
    this.popoverCtrl.dismiss(this.feedData, type);

    // this.popoverCtrl.dismiss().then((data) => {
    // });

  }

  openItem(type) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.feedData.id,
      }
    }
    this.router.navigate(['/dashboard/' + type + '/'], navigationExtras);
  }

}
