import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
const USER_DATA = 'user-data';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { MessageService } from './message.service';
import { param } from 'jquery';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    feedWeb: boolean = false;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private message: MessageService) {
        this.message.feedWeb.subscribe(res => {
            this.feedWeb = res;
        })
    }

    // Api_URL: string = 'https://spazada-staging.azurewebsites.net/api/';
    // Api_URL: string = 'https://spazada.azurewebsites.net/api/';
    Api_URL: string = 'https://www.spa.studio/api/'

    // closebypackage for location page.
    getCloseByPackage(data): Observable<any> {
        return this.http.get(this.Api_URL + `Package?is_special=${data.flag}&site_country=${data.country_id}&site_id=1&skip=${data.skip}&page_size=${data.page_size}&service_id=${data.service_id}&city=${data.city}&rating=${data.rating}&keyword=${data.keyword}&cur_lat=${data.cur_lat}&cur_long=${data.cur_long}&min_price=${data.min_price}&max_price=${data.max_price}`)
    }

    // specailPackage for location page.
    getSpecialPackage(data): Observable<any> {
        return this.http.get(this.Api_URL + `Package?is_special=${data.flag}&site_country=${data.country_id}&site_id=1&skip=${data.skip}&page_size=${data.page_size}&service_id=${data.service_id}&city=${data.city}&rating=${data.rating}&keyword=${data.keyword}&cur_lat=${data.cur_lat}&cur_long=${data.cur_long}&min_price=${data.min_price}&max_price=${data.max_price}`)
    }

    // Spa Lists
    getAllSpasList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpa?site_id=1&country_id=${data.countryId}&skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&city=${data.city}&rating=${data.rating}&keyword=${data.keyword}`)
    }

    // Spa Details
    getSpaDetails(spaId): Observable<any> {
        return this.http.get(this.Api_URL + `appSpa?spa_id=${spaId}`)
    }

    // Spa Details
    getSpaByType(data): Observable<any> {
        return this.http.get(this.Api_URL + `Spa?type=${data.type}&site_id=1&spa_id=${data.spa_id}`)
    }

    //Spa Services
    getSpaServices(country_id): Observable<any> {
        return this.http.get(this.Api_URL + `Services?site_id=1&country_id=${country_id}`)
    }

    //Package Lists
    getAllPackageList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appPackage?site_id=1&is_special=${data.flag}&site_country=${data.countryId}&skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&city=${data.city}&rating=${data.rating}&keyword=${data.keyword}&cur_lat=${data.cur_lat}&cur_long=${data.cur_long}&min_price=${data.min_price}&max_price=${data.max_price}`)
    }

    //Package Lists
    getPackageDetails(data): Observable<any> {
        // return this.http.get(this.Api_URL+`appPackage?country_id=${data.country_id}&package_id=${data.package_id}`)
        return this.http.get(this.Api_URL + `appPackage?country_id=${data.country_id}&package_id=${data.package_id}`)
    }

    //Videos Lists
    getAllVideoList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appReferences?skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&rating=${data.rating}&keyword=${data.keyword}`)
    }

    //Video Detail
    getVideoDetail(id): Observable<any> {
        return this.http.get(this.Api_URL + `appReferences?id=${id}`)
    }

    //Video Comments
    getVideoComments(data): Observable<any> {
        return this.http.get(this.Api_URL + `VideoComment?video_id=${data.fk_video_id}&user_id=${data.user_id}`)
    }

    //Video Likes Get
    getVideoLikes(data): Observable<any> {
        return this.http.get(this.Api_URL + `VideoLike?fk_user_id=${data.fk_user_id}&fk_video_id=${data.fk_video_id}`);
    }

    //Video Likes Post
    postVideoLikes(data): Observable<any> {
        return this.http.post(`https://spazada.azurewebsites.net/api/VideoLike/`, data);
    }

    //Video Related Records For Video Page
    RelatedVideosVList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedVideos?pk_video_id=${data.video_id}&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Users Related Records For Video Page
    RelatedUsersVList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedUsers?cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Products Related Records For Video Page
    RelatedProductsVList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedProducts?pk_product_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Article Related Records For Video Page
    RelatedArticleVList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedArticles?pk_article_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Products Lists
    getAllProductList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appProduct?skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&rating=${data.rating}&keyword=${data.keyword}`)
    }

    //Products Details
    getProductsDetails(data): Observable<any> {
        return this.http.get(this.Api_URL + `appProduct?id=${data.id}&type=${data.type}`)
        // return this.http.get('https://spazada-staging.azurewebsites.net/api/appProduct?id=4654&type=product');
    }

    // Getting product of cart 
    getCartItems(data): Observable<any> {
        //return this.http.get(this.Api_URL + `appShoppingCart?fk_user_id=4&type=data`)
        return this.http.get(`https://www.spa.studio/api/appShoppingCart?type=cart&fk_user_id=4`);
    }

    // Adding product to cart 
    addToCart(data): Observable<any> {
        return this.http.post(this.Api_URL + `appShoppingCart/`, data)
    }

    // Deleting product of cart 
    deleteFromCart(data): Observable<any> {
        return this.http.delete(this.Api_URL + `appShoppingCart/${data.id}`)
    }

    // Adding product to cart 
    postIsItemOrder(data): Observable<any> {
        //  return this.http.get(this.Api_URL + `appIsItemOrder?user_id=${data.user_id}&product_id=${data.product_id}&value=${data.value}`);
        return this.http.post(this.Api_URL + `appIsItemOrder?user_id=${data.user_id}&product_id=${data.product_id}&value=${data.value}`, null)
    }

    // Adding product to WhisList 
    postWishList(data): Observable<any> {
        return this.http.post(`https://spazada.azurewebsites.net/api/appWishList/`, data)
    }

    //Video Related Records For Product Page
    RelatedVideosPList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedVideos?pk_video_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Users Related Records For Product Page
    RelatedUsersPList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedUsers?cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Products Related Records For Product Page
    RelatedProductsPList(data): Observable<any> {
        // return this.http.get(this.Api_URL+`appRelatedProducts?pk_product_id=${data.product_id}&cat_id=${data.cat_id}&title=${data.title}`);
        return this.http.get('https://spazada-staging.azurewebsites.net/api/appRelatedProducts?pk_product_id=4654&cat_id=47&title=Aesthetics');
    }

    //Article Related Records For Product Page
    RelatedArticlesPList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedArticles?pk_article_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Peoples Lists
    getAllPeopleList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appUser?skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&rating=${data.rating}&keyword=${data.keyword}`)
    }
    // getAllPeopleList(data): Observable<any> { 
    //     return this.http.get(`https://spazada-staging.azurewebsites.net/api/appUser?skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&rating=${data.rating}&keyword=${data.keyword}`)
    // }

    //Articles Lists
    getAllArticleList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appArticle?skip=${data.skip}&page_size=${data.page_size}&service_id=${data.serviceId}&rating=${data.rating}&keyword=${data.keyword}`)
    }

    //Articles Details
    getArticleDetails(data): Observable<any> {
        return this.http.get(this.Api_URL + `appArticle?id=${data.article_id}`)
    }

    //Articles Comments
    getArticleComments(data): Observable<any> {
        return this.http.get(this.Api_URL + `appArticleComment?article_id=${data.article_id}&user_id=${data.user_id}`)
    }

    //Video Related Records For Article Page
    RelatedVideosList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedVideos?pk_video_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Users Related Records For Article Page
    RelatedUsersList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedUsers?cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Products Related Records For Article Page
    RelatedProductsList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedProducts?pk_product_id=0&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Article Related Records For Article Page
    RelatedArticlesList(data): Observable<any> {
        return this.http.get(this.Api_URL + `appRelatedArticles?pk_article_id=${data.article_id}&cat_id=${data.cat_id}&title=${data.title}`);
    }

    //Spa Profile Packages
    getSpaPackages(data): Observable<any> {
        return this.http.get(this.Api_URL + `appPackage?country_id=${data.countryId}&spa_id=${data.spaId}&category_id=${data.categoryId}`)
    }

    //Home Page Feeds
    getHomePageFeeds(data): Observable<any> {
        return this.http.get(this.Api_URL + `${this.feedWeb ? 'FeaturedHomepageFeed' : 'HomepageFeed'}?page_no=${data.take}&page_skip=${data.skip}&spa_id=${data.user_id}&fk_country_id=${data.fk_country_id}&service_id=${data.service_id}&keyword=${data.keyword}&search_type=${data.search_type}`)
    }

    //Home Page Feed like
    postFeedLike(data): Observable<any> {
        return this.http.post(`https://spazada.azurewebsites.net/api/VideoLike/`, data)
    }

    // Messages list
    getAllMessagesList(param): Observable<any> {
        return this.http.get(this.Api_URL + `appPeopleMessage?user_id=${param.fk_sender_id}`)
    }

    // Message Details
    getMessageDetails(param): Observable<any> {
        return this.http.get(this.Api_URL + `appPeopleMessage?user_id=${param.fk_sender_id}&receiver_id=${param.fk_receiver_id}&skip=${param.skip}&take=${param.take}`)
    }

    // Availability list of a package
    getStaff(package_id): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaStaff?package_id=${package_id}`)
    }

    // Available spa package timings.
    getSpaTimings(package_id, date): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaStaff?package_id=${package_id}&date=${date}`)
    }

    //Article Details
    getAppArticle(param): Observable<any> {
        return this.http.get("https://spazada.azurewebsites.net/api/appArticle?id=1967")
        //return this.http.get("https://spazada.azurewebsites.net/api/" + 'appArticle?id=3937')
    }

    getAppArticleList(data): Observable<any> {
        return this.http.get(` https://spazada.azurewebsites.net/api/appArticle?skip=0&page_size=5&service_id=33&rating=undefined&keyword=`)
    }

    getAppArticleVideo(data): Observable<any> {
        return this.http.get('https://spazada.azurewebsites.net/api/appRelatedVideos?pk_video_id=0&cat_id=47&title=10-Anti-Ageing-Secrets-Women-Wish-They-Knew-in-their-20s')
    }

    getAppArticleProducts(data): Observable<any> {
        // return this.http.get('https://spazada-staging.azurewebsites.net/api/appProduct?skip=0&page_size=5&service_id=33&rating=undefined&keyword=');
        return this.http.get('https://spazada.azurewebsites.net/api/appRelatedProducts?pk_product_id=4654&cat_id=47&title=Aesthetics')
    }

    getAppPeopleProfile(data): Observable<any> {
        return this.http.get(this.Api_URL + `appUser?user_id=${data.id}&type=${data.type}`)
    }

    //Add Article
    AddArticle(data): Observable<any> {
        return this.http.post(this.Api_URL + `appArticle/`, data)
    }

    getAllForumComments(data): Observable<any> {
        return this.http.get("https://spazada.azurewebsites.net/api/appArticleComment?article_id=1890&user_id=19154")
    }

    getUserProfile(data): Observable<any> {
        return this.http.get(this.Api_URL + `User?type=${data.type}&site_id=1&User_id=${data.id}`)
    }

    bookAPackage(data): Observable<any> {
        return this.http.post(`https://spazada.azurewebsites.net/api/appSpabooking/`, data);
    }

    // getting SubCategories
    getsubCateogry(data): Observable<any> {
        return this.http.get(this.Api_URL + `Services?fk_parent_id=${data.fk_parent_id}&type=${data.type}`)
    }

    getSearchDropdownData(data): Observable<any> {
        return this.http.get(this.Api_URL + `Dropdown?type=${data.type}&country_id=${data.country_id}`)
    }

    //Add Article Comment
    postAppArticleComment(param): Observable<any> {
        return this.http.post(this.Api_URL + `Parley/`, param)
    }

    //Add Video Comment
    postVideoComment(param): Observable<any> {
        return this.http.post(this.Api_URL + `VideoComment/`, param)
    }

    //Add Spa Review
    setSchoolReviewComments(param): Observable<any> {
        return this.http.post(this.Api_URL + `appSpaReview/`, param)
    }

    updateProfile(param): Observable<any> {
        return this.http.post(this.Api_URL + `appUser/`, param)
    }

    postInterestProvide(data): Observable<any> {
        return this.http.post(this.Api_URL + 'appInterestProvide?user_id=', +data.id + '&category_id=' + data.category_id + '&type=' + data.type)
        //get Product Order Items
    }
    //get Product Order Items
    getProductOrderItems(param): Observable<any> {
        return this.http.get("https://spazada.azurewebsites.net/api/appProductOrderItem?order_id=92")
    }

    //get all comments message section
    getAllComments(param): Observable<any> {
        return this.http.get(this.Api_URL + `appArticleComment?user_id%20=${param.user_id}&skip=${param.skip}&page_size=${param.page_size}`)
    }

    //Cancel Spa Bookinf
    CancelBooking(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaCancelBooking?booking_id=${data}`)
    }

    //Send Message
    SendMessage(data): Observable<any> {
        return this.http.post(this.Api_URL + `appPeopleMessage/`, data)
    }

    //Voucher Appply Checkout Page
    VoucherApply(data): Observable<any> {
        return this.http.get(this.Api_URL + `appVoucher?price=${data.price}&code=${data.code}`)
    }

    //Get Booking Details | Checkout Package Page
    BookingDetails(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaBooking?type=Details&id=${data}`);
    }

    //Get Bookings for Spa Owner
    getspaOrders(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaOrder?spa_id=${data.spa_id}`);
    }

    //Get packages for Spa Owner
    getspaPackage(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaPackage?spa_id=${data.spa_id}&site_country=${data.countryId}`);
    }

    //Get users for Spa Owner
    getspaUsers(data): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaOwnerBooking?spa_id=${data.spa_id}`);
    }

    //Get users booking cancelled
    cancelBooking(id): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaCancelBooking?booking_id=${id}`);
    }

    //Get users booking cancelled
    confirmBooking(id): Observable<any> {
        return this.http.get(this.Api_URL + `appSpaEmailConfirmation?booking_id=${id}`);
    }

    //Comment Upvote
    LikeComment(data): Observable<any> {
        return this.http.post(this.Api_URL + `appCommentVoting`, data);
    }

    //Add a Package
    addAPackage(data): Observable<any> {
        return this.http.post(this.Api_URL + `appPackage`, data);
    }

    //Add a Package
    addNewSpa(data): Observable<any> {
        return this.http.post(this.Api_URL + `appSpa`, data);
    }

    //Marking as paid
    setAsPaid(data): Observable<any> {
        return this.http.put(this.Api_URL + `appSpabooking/`, data);
    }

    //Updating a package records
    updateAPackage(data): Observable<any> {
        return this.http.put(this.Api_URL + `appPackage/`, data);
    }

    //Admin Profile
    AdminProfile(data): Observable<any> {
        return this.http.post(this.Api_URL + `appSpa/`, data);
    }

    //Reset Password
    ResetPassword(data): Observable<any> {
        return this.http.get(this.Api_URL + 'Login?email=' + data.username + '&type=' + data.type);
    }

}