import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // return next.handle(req);
        const modifiedReq = req.clone({
            // params: new HttpParams().set('Authorization', 'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFibWx4ZFdWZmJtRnRaU0k2SWxOd1lTQlRkSFZrYVc4aUxDSnliMnhsSWpvaVZYTmxjaUlzSW01aVppSTZNVFkxT0RreE56VTNPQ3dpWlhod0lqb3hOall4TlRrMU9UYzRMQ0pwWVhRaU9qRTJOVGc1TVRjMU56Z3NJbWx6Y3lJNklrVkNVMDRpTENKaGRXUWlPaUpGUWxOT0luMC42NnpqLUI3WHUxRjM0a2o3d3VCU0U1ZkVZaVk2eEtmdjJrM1NmVWl4YXU4==')
            setHeaders: {
                Authorization: 'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFibWx4ZFdWZmJtRnRaU0k2SWxOd1lTQlRkSFZrYVc4aUxDSnliMnhsSWpvaVZYTmxjaUlzSW01aVppSTZNVFkxT1Rrek9USXdOaXdpWlhod0lqb3hOall5TmpFM05qQTJMQ0pwWVhRaU9qRTJOVGs1TXpreU1EWXNJbWx6Y3lJNklsTlFRVk5VVlVSSlR5SXNJbUYxWkNJNklsTlFRVk5VVlVSSlR5SjkuRlZnSVlUbmhOOTZXVDRLV2Q4cnZHdWY0dDQxT0lhVzJXY3ZPNTBHSGhlYw=='
            }
        })
        return next.handle(modifiedReq);
    }

}
