import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, Platform, PopoverController } from '@ionic/angular';
import { AppService } from '../../providers/app.services';
import { Storage } from '@ionic/storage';
import { PopoverComponent } from '../../components/popover/popover.component'
import { MessageService } from '../../providers/message.service';
import { NavigationExtras } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AuthenticationService } from '../../providers/authentication.service';




@Component({
  selector: 'app-home-page-feeds',
  templateUrl: './home-page-feeds.page.html',
  styleUrls: ['./home-page-feeds.page.scss'],
})
export class HomePageFeedsPage implements OnInit {

  homeFeedItems: any[] = [];
  catList: any[] = [];
  skip: any = 0;
  page_size: any = 2;
  feed_id: number;
  user: any;
  feed = {
    keyword: '',
    search_type: ''
  }
  category_id: any = '';
  cat_data: any = false;
  countryId: any = '';
  feed_service_id: any = ''
  currentPopover = null;
  showSearch: boolean = false;
  selectedType: number;
  typeChange: boolean = false;
  keyword_data: boolean = false;
  pageRefreshed: boolean = false;
  temphomeFeedItems: any;
  platfromType: string[];
  showWebContents: boolean = false;
  isloggedIn: any = '';
  loadMoreFeeds: any = false;


  constructor(
    private popoverCtrl: PopoverController,
    private storage: Storage,
    private appService: AppService,
    private loadingController: LoadingController,
    private router: Router,
    private toaster: MessageService,
    private message: MessageService,
    private platform: Platform,
    private authService: AuthenticationService,
    private title: Title,
    private meta: Meta
  ) {
    // to define a deafult country
    this.storage.set('countryId', 154);
    this.message.feedWeb.subscribe(res => {
      this.showWebContents = res;
    });
  }


  // records for horizontal services on top.
  servicesJSON = [
    { name: 'Aesthetics', img: 'aesthetics-47.webp', id: 47 },
    { name: 'Aromatherapy', img: 'aromatherapy-33.webp', id: 33 },
    { name: 'Barber', img: 'barber-48.webp', id: 48 },
    { name: 'Bathing', img: 'bathing-34.webp', id: 34 },
    { name: 'Bodywraps', img: 'bodywraps-31.webp', id: 31 },
    { name: 'Earcandling', img: 'earcandling-35.webp', id: 35 },
    { name: 'Exfoliation', img: 'exfoliation-36.webp', id: 36 },
    { name: 'Eyecare', img: 'eyecare-37.webp', id: 37 },
    { name: 'Facials', img: 'facials-21.webp', id: 21 },
    { name: 'Haircare', img: 'haircare-30.webp', id: 30 },
    { name: 'Hair Removal', img: 'hairremoval-32.webp', id: 32 },
    { name: 'Makeup', img: 'makeup-69.webp', id: 69 },
    { name: 'Manicure & pedicures', img: 'manicure&pedicures-39.webp', id: 39 },
    { name: 'Massage', img: 'massage-22.webp', id: 22 },
    { name: 'Maternity', img: 'maternity-276.webp', id: 276 },
    { name: 'Meditation', img: 'meditation-40.webp', id: 40 },
    { name: 'Nutrition', img: 'nutrition-41.webp', id: 41 },
    { name: 'Peeling Treatment', img: 'peelingtreatment-42.webp', id: 42 },
    { name: 'Personaltraining', img: 'personaltraining-43.webp', id: 43 },
    { name: 'Skincare', img: 'skincare-23.webp', id: 23 },
    { name: 'Slimming', img: 'slimming-44.webp', id: 44 },
    { name: 'Teeth', img: 'teeth-67.webp', id: 67 },
    { name: 'Therapeutictreatment', img: 'therapeutictreatment-45.webp', id: 45 },
    { name: 'Yoga', img: 'yoga-46.webp', id: 46 }
  ];

  // records to load locally.
  bakedFeeds = [
    {
      title: 'Best & Worst Cleansing Wipes',
      description: "Today I share with you the best and worst cleansing wipes I've used.",
      embedId: 't8y90cAyslY',
      id: 740
    }, {
      title: 'Aromatherapy: How Essential are Essential Oils?',
      description: "Aromatherapy is a method of natural healing with Essential Oils.",
      embedId: 'UF4VYMWwo1M',
      id: 853
    }, {
      title: '7 Best Pre Workouts for Fat Loss, Cardio, and More',
      description: "Common Pre Workout Ingredients: Caffeine For Elevated Energy.",
      embedId: 'mqZ4b-tqsxY',
      id: 1640
    }, {
      title: 'Himalaya Ayurveda Clear Skin Soap.',
      description: "Ayurvedic oil bath, now in a soap. Presenting the new Himalaya Ayurveda Clear skin soap.",
      embedId: 'ng_WkBFZiPo',
      id: 2109
    }, {
      title: 'Sunless Tanner for Face WITHOUT Breakouts',
      description: "It's officially starting to feel like spring is turning toward summer",
      embedId: 'ZsvduajZ2A0',
      id: 2020
    }, {
      title: 'Gigi Hair Removal Cream',
      description: "Excess facial hair (brows, &ldquo;and other places&rdquo;) is something most women deal with.",
      embedId: '-tbi56EucaM',
      id: 581
    }, {
      title: 'Unicorn Kids Room Aromatherapy Diffuser for Essential Oils',
      description: "Unicorn USB Powered Aromatherapy Diffuser is the first of its kind. With our new 'Silicone Soft Top Design'",
      embedId: 'mY0OdBH2SoU',
      id: 925
    }, {
      title: 'Lifebuoy Soap | Lifebuoy Germ Protection Soap',
      description: "Lifebuoy Soap | Lifebuoy Germ Protection Soap | Lifebuoy Soap Review | How to Wash Hands for Germs.",
      embedId: '3wkB0DTTmj0',
      id: 1713
    }, {
      title: "Ultimate Makeup Brushes Guide! 38 Makeup Brushes and Their Uses",
      description: "38 Makeup brushes and their uses! The ultimate makeup brush guide for your makeup brushes 101 needs!.",
      embedId: 'UwAhiInfz8s',
      id: 249
    }
  ];

  searchType = [
    { icon: 'video-camera', type: 'video', name: 'Video' },
    { icon: 'cube', type: 'package', name: 'Package' },
    { icon: 'shopping-cart', type: 'product', name: 'Product' },
    { icon: 'comments', type: 'article', name: 'Article' },
    { icon: 'leaf', type: 'spa', name: 'Spa' }
  ]

  async ionViewDidEnter() {

    console.log(this.platform.platforms());

    this.countryId = await this.storage.get("countryId");

    this.storage.get('user').then((val) => {
      this.user = val;
    });

    // getting category ID from storage
    this.storage.get('category_id').then((val) => {
      this.category_id = JSON.parse(val);
    });


    // await loading.present();
    // setTimeout(() => {
    //   this.loadHomePageFeeds();
    // }, 500);
  }

  //New Search Function
  searchParam: any = {
    take: this.page_size,
    skip: this.skip,
    user_id: null,
    fk_country_id: this.countryId,
    keyword: "",
    service_id: this.feed_service_id ? this.feed_service_id : null,
    search_type: ""
  }

  NewSearch(){
    console.log("Keyword = " + this.searchParam.keyword);
    this.searchParam.fk_country_id = this.countryId ? this.countryId : 154;
    this.showWebContents ? this.searchParam.take = 10 : this.page_size;
    this.appService.getHomePageFeeds(this.searchParam).subscribe(
      async (res) => {
        this.temphomeFeedItems = res;
        // filters the embedID from the video URL and puts it back to the url
        if (!this.feed.keyword && !this.typeChange) {
          this.temphomeFeedItems ? this.temphomeFeedItems.forEach(function (value) {
            if (value.url)
              value.url = value.url.split('/')[4];
          }) : '';
        }
        if (this.feed_service_id) {
          if (this.cat_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && !this.typeChange) {
          this.typeChange = true;
          if (this.keyword_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && this.typeChange) {
          this.homeFeedItems = res.feed_list;
          this.catList = res.cat_list;
          this.typeChange = true;
        }
        else if (this.pageRefreshed) {
          this.homeFeedItems = [];
          this.homeFeedItems = this.addBannner(res);
        }
        else if (this.loadMoreFeeds) {
          this.homeFeedItems = this.homeFeedItems.concat(this.addBannner(res));
        } 
        else {
          this.homeFeedItems = this.addBannner(res);
        }
      },
      async (error) => {
        this.toaster.showToaster('Something went wrong while feching feeds');
      }
    );
  }

  async ngOnInit() {
    this.title.setTitle('Spa Studio - Feeds');
    this.meta.addTags([
      { name: 'keywords', content: 'Spa Studio, spa.studio, spa' },
      { name: 'description', content: 'Spa Studio where you can find piece and relaxation for you soul' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.authService.isAuthenticated.subscribe(res => {
      this.isloggedIn = res;
    });
    // await loading.present();
    setTimeout(() => {
      this.loadHomePageFeeds();
    }, 500);
  }

  async ngAfterViewInit() {
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
    // if (this.showSearch) {
    //   document.querySelector('#webSearchDiv').classList.remove('hidden');
    // } else {
    //   document.querySelector('#webSearchDiv').classList.add('hidden');
    // }

    document.querySelector('#webSearchDiv').classList.remove('hidden');
    console.log(this.showSearch);
  }

  Keyword: any;

  searchResults = function () {
    if (this.keyword)
      this.loadHomePageFeeds();
    else
      this.toaster.showToaster('Please enter a keyword');
  }

  gotoPanel() {
    if (this.isloggedIn == true)
      this.router.navigate(['/dashboard/panel']);
    else
      this.router.navigate(['/dashboard/login/']);
  }

  gotoMessage() {
    if (this.isloggedIn == true)
      this.router.navigate(['/dashboard/message']);
    else
      this.router.navigate(['/dashboard/login/']);
  }

  gotoProfile() {
    if (this.isloggedIn == true)
      this.router.navigate(['/dashboard/profile']);
    else
      this.router.navigate(['/dashboard/login/']);
  }

  //selecting type from icon in the search
  changeType = function (type) {
    this.typeChange = true;
    this.take = 10;
    if (type)
      this.feed.search_type = type;

    if (this.feed.keyword)
      this.loadHomePageFeeds();
    else
      this.toaster.showToaster('Please enter a keyword');
  }


  loadFeedByServiceId(data) {
    let feedparam: any = {
      take: this.page_size,
      skip: this.skip,
      user_id: null,
      fk_country_id: this.countryId,
      keyword: data.name,
      service_id: data.id,
      search_type: this.feed.search_type
    }
    if (this.feed.keyword) {
      this.feed.keyword = '';
    }
    this.cat_data = false;
    this.category_id = data.id;
    this.feed_service_id = data.id;
    this.storage.set('category_id', data.id);
    //this.loadHomePageFeeds();
    // this.appService.getHomePageFeeds(feedparam).subscribe(
    //   async(res)=> {
    //     console.log(res);
    //     this.homeFeedItems = res;

    //   }
    // );

    this.appService.getHomePageFeeds(feedparam).subscribe(
      async (res) => {
        this.temphomeFeedItems = res;
        // filters the embedID from the video URL and puts it back to the url
        if (!this.feed.keyword && !this.typeChange) {
          this.temphomeFeedItems ? this.temphomeFeedItems.forEach(function (value) {
            if (value.url)
              value.url = value.url.split('/')[4];
          }) : '';
        }
        if (this.feed_service_id) {
          if (this.cat_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && !this.typeChange) {
          this.typeChange = true;
          if (this.keyword_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && this.typeChange) {
          this.homeFeedItems = res.feed_list;
          this.catList = res.cat_list;
          this.typeChange = true;
        }
        else if (this.pageRefreshed) {
          this.homeFeedItems = [];
          this.homeFeedItems = this.addBannner(res);
        }
        else if (this.loadMoreFeeds) {
          this.homeFeedItems = this.homeFeedItems.concat(this.addBannner(res));
        } 
        else {
          this.homeFeedItems = this.addBannner(res);
        }
      },
      async (error) => {
        this.toaster.showToaster('Something went wrong while feching feeds');
      }
    );
  }

  gotoServiceDetails(id, title) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        title: title
      }
    }

    this.router.navigate(['/dashboard/service-details/'], navigationExtras);
  }

  // Presents to Popover 
  async presentPopover(ev: any, cate_title, id, feed_title, cat_id, type) {
    let feedData = {
      id: id,
      title: feed_title,
      cate_title: cate_title,
      category_id: cat_id,
      recordType: type,
    }
    this.storage.set("category_id", cat_id);

    const popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps: { feedData: feedData },
      event: ev,
      translucent: true
    });

    // Fetches the records of dismissed popover.
    popover.onDidDismiss().then((data: any) => {
      this.feed_id = data.data.feed_id;
      if (data.role != "backdrop" && data.role == "Like Video") {
        // API Calling for homePage Feeds Likes
        let param = {
          fk_video_id: this.feed_id,
          fk_user_id: this.user.id ? this.user.id : null,
          isLike: true
        }
        this.appService.postFeedLike(param).subscribe(
          async (res) => {
          },
          async (error) => {
            this.toaster.showToaster('Something went wrong');
          }
        );
      }
      else if (data.role != "backdrop" && data.role == "SimilarItems") {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            id: data.data.category_id,
            recordtype: data.data.recordType,
            from: 'home'
          }
        }
        this.router.navigate(['/dashboard/service-details'], navigationExtras)
      }
    })

    return await popover.present();
  }

  // Loading the HomePageFeed records
  feedparam: any = {
    take: this.page_size,
    skip: this.skip,
    user_id: null,
    fk_country_id: this.countryId,
    keyword: this.feed.keyword,
    service_id: this.feed_service_id ? this.feed_service_id : null,
    search_type: this.feed.search_type
  }
  async loadHomePageFeeds() {
    const loading = await this.loadingController.create();
    await loading.present();

    // API Calling for homePage Feeds
    this.feedparam.fk_country_id = this.countryId ? this.countryId : 154;
    this.showWebContents ? this.feedparam.take = 10 : this.page_size;
    this.appService.getHomePageFeeds(this.feedparam).subscribe(
      async (res) => {
        this.temphomeFeedItems = res;
        // filters the embedID from the video URL and puts it back to the url
        if (!this.feed.keyword && !this.typeChange) {
          this.temphomeFeedItems ? this.temphomeFeedItems.forEach(function (value) {
            if (value.url)
              value.url = value.url.split('/')[4];
          }) : '';
        }
        await loading.dismiss();
        if (this.feed_service_id) {
          if (this.cat_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && !this.typeChange) {
          this.typeChange = true;
          if (this.keyword_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && this.typeChange) {
          this.homeFeedItems = res.feed_list;
          this.catList = res.cat_list;
          this.typeChange = true;
        }
        else if (this.pageRefreshed) {
          this.homeFeedItems = [];
          this.homeFeedItems = this.addBannner(res);
        }
        else if (this.loadMoreFeeds) {
          this.homeFeedItems = this.homeFeedItems.concat(this.addBannner(res));
        } 
        else {
          this.homeFeedItems = this.addBannner(res);
        }
      },
      async (error) => {
        await loading.dismiss();
        this.toaster.showToaster('Something went wrong while feching feeds');
      }
    );
  }

  Refresh(event){
    
    this.feedparam.fk_country_id = this.countryId ? this.countryId : 154;
    this.showWebContents ? this.feedparam.take = 10 : this.page_size;
    this.appService.getHomePageFeeds(this.feedparam).subscribe(
      async (res) => {
        this.temphomeFeedItems = res;
        // filters the embedID from the video URL and puts it back to the url
        if (!this.feed.keyword && !this.typeChange) {
          this.temphomeFeedItems ? this.temphomeFeedItems.forEach(function (value) {
            if (value.url)
              value.url = value.url.split('/')[4];
          }) : '';
        }
        if (this.feed_service_id) {
          if (this.cat_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && !this.typeChange) {
          this.typeChange = true;
          if (this.keyword_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (this.feed.keyword && this.typeChange) {
          this.homeFeedItems = res.feed_list;
          this.catList = res.cat_list;
          this.typeChange = true;
        }
        else if (this.pageRefreshed) {
          this.homeFeedItems = [];
          this.homeFeedItems = this.addBannner(res);
        }
        else if (this.loadMoreFeeds) {
          this.homeFeedItems = this.homeFeedItems.concat(this.addBannner(res));
        } 
        else {
          this.homeFeedItems = this.addBannner(res);
        }
      },
      async (error) => {
        this.toaster.showToaster('Something went wrong while feching feeds');
      }
    );

    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  resetSearch() {
    //Empty Function
  }

  async searchloadHomePageFeeds(data) {

    let feedparam: any = {
      take: this.page_size,
      skip: this.skip,
      user_id: null,
      fk_country_id: this.countryId,
      keyword: data.keyword,
      service_id: this.feed_service_id ? this.feed_service_id : null,
      search_type: data.search_type
    }
    const loading = await this.loadingController.create();
    await loading.present();

    // API Calling for homePage Feeds
    feedparam.fk_country_id = this.countryId ? this.countryId : 154;
    this.showWebContents ? feedparam.take = 5 : this.page_size;
    this.appService.getHomePageFeeds(feedparam).subscribe(
      async (res) => {
        this.temphomeFeedItems = res;
        // filters the embedID from the video URL and puts it back to the url
        if (!data.keyword && !this.typeChange) {
          this.temphomeFeedItems ? this.temphomeFeedItems.forEach(function (value) {
            if (value.url)
              value.url = value.url.split('/')[4];
          }) : '';
        }
        await loading.dismiss();
        if (this.feed_service_id) {
          if (this.cat_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (data.keyword && !this.typeChange) {
          this.typeChange = true;
          if (this.keyword_data) {
            this.homeFeedItems = this.homeFeedItems.concat(res);
          }
          else {
            this.homeFeedItems = [];
            this.homeFeedItems = res;
          }
        }
        else if (data.keyword && this.typeChange) {
          this.homeFeedItems = res.feed_list;
          this.catList = res.cat_list;
          this.typeChange = true;
        }
        else {
          if (this.pageRefreshed) {
            this.homeFeedItems = [];
            this.homeFeedItems = this.addBannner(res);
          }
          else {
            // this.homeFeedItems = this.homeFeedItems.concat(this.addBannner(res));
            this.homeFeedItems = this.addBannner(res);
          }
        }
      },
      async (error) => {
        await loading.dismiss();
        this.toaster.showToaster('Something went wrong while feching feeds');
      }
    );
  }

  loadMoreData(event) {
    this.loadMoreFeeds = true;
    if (this.feed_service_id)
      this.cat_data = true;
    if (this.feed.keyword)
      this.keyword_data = true;
    this.loadHomePageFeeds();
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  addBannner(resp) {
    let number = Math.floor(Math.random() * 2) + 1;
    let data = {
      imgurl: 'spastudio-tile-' + number + '.png',
      feed_type: 'banner'
    }
    let bannerSkip = 9;
    for (let i = 1; i < resp.length; i++) {
      if (i == bannerSkip) {
        resp.splice(bannerSkip, 0, data);
        bannerSkip += 10;
      }
    }
    return resp
  }

  gotoRerenceVideo = function (id, title, cat_id) {
    title = title.replace(/ ,?/g, "-");
    this.category_id = cat_id;
    localStorage.setItem("category_id", cat_id);
    //this.router.navigateByUrl('/dashboard/video-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        title:title,
        serviceid: cat_id,
        from: 'home'
      }
    }
    this.router.navigate(['/dashboard/video-details'], navigationExtras);
  };


  gotoPackageDetails = function (id, title) {
    title = title.replace(/ ,?/g, "-");
    //this.router.navigateByUrl('/dashboard/package-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        title:title,
        from: 'home'
      }
    }
    this.router.navigate(['/dashboard/package-details'], navigationExtras);
  };


  gotoProductDetails = function (id, title, cat_id) {
    title = title.replace(/ ,?/g, "-");
    this.category_id = cat_id;
    localStorage.setItem("category_id", cat_id);
    //this.router.navigateByUrl('/dashboard/product-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        title:title,
        serviceid: cat_id,
        from: 'home'
      }
    }
    this.router.navigate(['/dashboard/product-details'], navigationExtras);
  };

  gotoArticle = function (id, title, cat_id) {
    title = title.replace(/ ,?/g, "-");
    this.category_id = cat_id;
    localStorage.setItem("category_id", cat_id);
    //this.router.navigateByUrl('/dashboard/article-details/' + id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        title:title,
        serviceid: cat_id,
        from: 'home'
      }
    }
    this.router.navigate(['/dashboard/article-details'], navigationExtras);
  };

  gotoSpaProfile = function (id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
        from: 'home'
      }
    }
    this.router.navigate(['/dashboard/spa-details'], navigationExtras);
    //this.router.navigateByUrl('/dashboard/spa-details/' + id);
  };
}
