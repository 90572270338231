import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/home-page-feeds',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(m => m.DashboardModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
  },
  {
    path: 'country',
    loadChildren: () => import('./pages/country/country.module').then(m => m.CountryPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./pages/message-list/message-list.module').then(m => m.MessageListPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'message-details',
    loadChildren: () => import('./pages/message-details/message-details.module').then(m => m.MessageDetailsPageModule)
  },
  {
    path: 'panel',
    loadChildren: () => import('./pages/panel/panel.module').then(m => m.PanelPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'service',
    loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/bookings/bookings.module').then(m => m.BookingsPageModule)
  },
  {
    path: 'service-details',
    loadChildren: () => import('./pages/service-details/service-details.module').then(m => m.ServiceDetailsPageModule)
  },
  {
    path: 'places',
    loadChildren: () => import('./pages/places/places.module').then(m => m.PlacesPageModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./pages/packages/packages.module').then(m => m.PackagesPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./pages/videos/videos.module').then(m => m.VideosPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'peoples',
    loadChildren: () => import('./pages/peoples/peoples.module').then(m => m.PeoplesPageModule)
  },
  {
    path: 'forums',
    loadChildren: () => import('./pages/forums/forums.module').then(m => m.ForumsPageModule)
  },
  {
    path: 'home-page-feeds',
    loadChildren: () => import('./pages/home-page-feeds/home-page-feeds.module').then(m => m.HomePageFeedsPageModule)
  },
  {
    path: 'video-details',
    loadChildren: () => import('./pages/video-details/video-details.module').then(m => m.VideoDetailsPageModule)
  },
  {
    path: 'product-details',
    loadChildren: () => import('./pages/product-details/product-details.module').then(m => m.ProductDetailsPageModule)
  },
  {
    path: 'package-details',
    loadChildren: () => import('./pages/package-details/package-details.module').then(m => m.PackageDetailsPageModule)
  },
  {
    path: 'article-details',
    loadChildren: () => import('./pages/article-details/article-details.module').then(m => m.ArticleDetailsPageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then(m => m.LocationPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'book-package',
    loadChildren: () => import('./pages/book-package/book-package.module').then(m => m.BookPackagePageModule)
  },
  {
    path: 'book-package-b',
    loadChildren: () => import('./pages/book-package-b/book-package-b.module').then(m => m.BookPackageBPageModule)
  },
  {
    path: 'check-out-package',
    loadChildren: () => import('./pages/check-out-package/check-out-package.module').then(m => m.CheckOutPackagePageModule)
  },
  {
    path: 'forum-article',
    loadChildren: () => import('./pages/forum-article/forum-article.module').then(m => m.ForumArticlePageModule)
  },
  {
    path: 'reset-pass',
    loadChildren: () => import('./pages/reset-pass/reset-pass.module').then(m => m.ResetPassPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'person-details',
    loadChildren: () => import('./pages/person-details/person-details.module').then(m => m.PersonDetailsPageModule)
  },
  {
    path: 'check-out',
    loadChildren: () => import('./pages/check-out/check-out.module').then(m => m.CheckOutPageModule)
  },
  {
    path: 'add-article',
    loadChildren: () => import('./pages/add-article/add-article.module').then(m => m.AddArticlePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: "on-board-account",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/on-board-account/on-board-account.module").then(m => m.OnBoardAccountPageModule
          ),
      }
    ]
  },
  {
    path: "home-page-feeds",
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/home-page-feeds/home-page-feeds.module").then((m) => m.HomePageFeedsPageModule),
      },

    ]},
  {
    path: 'spa-owner-panel',
    loadChildren: () => import('./pages/spa-owner-panel/spa-owner-panel.module').then( m => m.SpaOwnerPanelPageModule)
  }

];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],

  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }